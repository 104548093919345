@import "variables";

#home-page-tabs{
	border-bottom: none;
	padding-left: 0;
	margin-bottom: 30px;
	text-align: center;
}
#home-page-tabs li{
	float: none;
	display: inline-block;
	padding-left: 15px;
	padding-right: 15px;
}
#home-page-tabs li a{
	background: none;
	padding: 0;
	border: none;
	font-size: 18px;
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	color: #333;
	margin: 0;
	border-bottom: transparent solid 3px;
	padding-bottom: 5px;
}
#home-page-tabs .active a{
	color: theme-color(secondary);
	border-color:theme-color(secondary);
}
